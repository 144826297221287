@import '@angular/cdk/overlay-prebuilt.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --num-of-days: 6;
  --num-of-hours: 20;
  --time-height: 1.4rem;
  --text-color-white: #f6f6f6;
  --belt-color-blue: #545ca7;
  --belt-color-brown: #785c63;
  --belt-color-purple: #7954a7;
  --cell-color: #fcfcfc;
  --grid-color: #f5f5f5;
  --event-color-bjj-gi: #8dec8d;
  --event-color-openmat-intern: #6e1bc7;
  --event-color-openmat-extern: #7b3fc0;
  --event-color-bjj-kids: #eeb756;
  --event-color-sanda-box: #eeee44;
  --event-color-bjj-no-gi: #adafa7;
  --event-color-bjj-women: #f370be;
  --event-color-bjj-sparring: #c61f1f;
  --event-color-bjj-drills: #328dbf;
}

.belt-color-blue {
  background-color: var(--belt-color-blue);
}

.belt-color-purple {
  background-color: var(--belt-color-purple);
}

.belt-color-brown {
  background-color: var(--belt-color-brown);
}

.trial-lesson-button {
  border: 1px solid #835454;
  border-radius: 0.25rem;
}

@layer components {
  .form-element {
    @apply w-full px-3 py-2 border rounded border-gray-300 focus:outline-none focus:border-blue-500 transition-colors duration-500 ease-in-out;
  }
}

cdk-dialog-container {
  outline-color: red !important;
}
